import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgIdleModule } from '@ng-idle/core';
import { LuxonModule } from 'luxon-angular';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { bootstrapApplication } from '@angular/platform-browser';
import { BasicAuthInterceptorService } from './app/helper/basic-auth-interceptor.service';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';

if (environment.production) {
  enableProdMode();
}

let routesPromise;
if (environment.maintenance) {
  routesPromise = import('./app/routes.maintenance').then(m => m.routes);
} else {
  routesPromise = import('./app/routes').then(m => m.routes);
}

routesPromise.then(routes => {
  bootstrapApplication(
    AppComponent,
    {
      providers: [
        provideRouter(routes),
        importProvidersFrom(
          NgbModule,
          AutocompleteLibModule,
          NgScrollbarModule.withConfig({
            visibility: 'hover',
            autoHeightDisabled: false,
          }),
          LuxonModule,
          NgIdleModule.forRoot()
        ),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BasicAuthInterceptorService,
          multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
      ]
    }
  ).catch(err => console.error(err));
});
