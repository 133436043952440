import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { EMPTY, map, switchMap } from 'rxjs';
import { PageTourService } from 'src/app/service/page-tour.service';
import { RouterEventsService } from 'src/app/service/router-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent {
  title = 'DocketScope';

  private pageRefCd: string | null = null;
  private tourEnabledTabPane: HTMLElement | null = null;

  constructor(
    private routerEventsService: RouterEventsService,
    private pageTourService: PageTourService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.routerEventsService.routerEvents.pipe(
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap(route => route?.data || EMPTY),
    ).subscribe(({title: pageTitle, pageRefCd}) => {
      this.pageRefCd = pageRefCd;

      this.titleService.setTitle(pageTitle ? this.title + ' | ' + pageTitle : this.title);
      this.pageTourService.setTour(pageRefCd);
      this.routerEventsService.pageRefCd.next(pageRefCd);
    });
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (this.pageRefCd && event?.target) {
      const el = event.target as HTMLElement;
      if (this.tourEnabledTabPane && this.tourEnabledTabPane.contains(el)) {
        return;
      }

      if (el.classList.contains('has-tour') && el.hasAttribute('data-tour-subkey') && el.hasAttribute('data-bs-target')!) {
        const paneId = el.getAttribute('data-bs-target')!.substring(1);
        this.tourEnabledTabPane = document.getElementById(paneId);
        this.pageTourService.setTour(this.pageRefCd, el.getAttribute('data-tour-subkey')!);
        return;
      }

      this.tourEnabledTabPane = null;
      this.pageTourService.setTour(this.pageRefCd);
    }
  }
}
